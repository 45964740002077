export const menu = [
  {
    value: 5,
    title: "Home",
    link: "",
  },
  {
    value: 1,
    title: "About",
    link: "about",
  },
  {
    value: 0,
    title: "Objectives",
    link: "objectives",
  },
  // {
  //   value: 3,
  //   title: "Projects",
  //   link: "research-project",
  // },
  {
    value: 7,
    title: "F.L.I.G.H.T",
    link: "FLIGHT",
  },
  {
    value: 2,
    title: "PoP",
    link: "pop",
  },

  // {
  //   value: 4,
  //   title: "ATF CoE",
  //   link: "cosmos-hub",
  // },

  {
    value: 6,
    title: "Subscribe",
    link: "subscribe",
  },
];

export const menu1 = [
  {
    value: 1,
    title: "Edit PoP",
    link: "/admin/editpop",
  },
  // {
  //   value: 0,
  //   title: "Objectives",
  //   link: "objectives",
  // },
  // {
  //   value: 3,
  //   title: "Edit Projects",
  //   link: "/admin/editproject",
  // },
  {
    value: 2,
    title: "Applied PoP",
    link: "pop",
  },

  {
    value: 4,
    title: "Subscribed Users",
    link: "Subscriedusers",
  },
  // {
  //   value: 5,
  //   title: "Blogs",
  //   link: "blogs",
  // },
  // {
  //   value: 6,
  //   title: "Subscribe",
  //   link: "subscribe",
  // },
];

export const atfcoes = [
  {
    value: 1,
    title: "MIT Institute of Design, Loni, Pune",
    logolink:
      "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/mitidlogo.jpeg?alt=media&token=9adafb39-8c98-4761-bdcf-734b8f23032c&_gl=1*1oi3d3z*_ga*NjE2NDg3NzMxLjE2OTA3Njc4Mjc.*_ga_CW55HF8NVT*MTY5OTIyMzM1My4yNi4xLjE2OTkyMjMzOTQuMTkuMC4w",
    program: {
      name: "Student Mobility Program",
    },
    project: {
      name: "Leather Studio",
      project1: "Leather Studio",
    },
    research: {
      name: "Research Projects",
      id: ["ATFSICELL001"],
    },
    pop: {
      name: "Professor Of Practice",
      id: ["ATFSD0001"],
    },
  },
  {
    value: 2,
    title: "Vishwakarma Institute of Information Technology, Pune",
    logolink:
      "https://firebasestorage.googleapis.com/v0/b/arcticturn-2-0.appspot.com/o/VIT-logo.png?alt=media&token=c912842d-759b-4ef0-803a-c10f972a1e64&_gl=1*1fmhz0z*_ga*NjE2NDg3NzMxLjE2OTA3Njc4Mjc.*_ga_CW55HF8NVT*MTY5OTIyMzM1My4yNi4xLjE2OTkyMjM0MjIuNjAuMC4w",
    program: {
      name: "Student Mobility",
      mobilityprogram1: "University of Nevada, Las Vegas",
    },
    project: {
      name: "Ongoing Project",
    },
    research: {
      name: "Research",
    },
  },
];
