export const Cohort2024UnlvList = [
  {
    value: 1,
    gender: "female",
    name: "Suhani Kolhatkar",
    project: "Cable driven parallel robots with unknown anchor points",
    professor: "Prof. Dr. Oh",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FSuhani.png?alt=media&token=ab8cdb39-a6f2-4cfa-8150-b2f0558f5ee4",
  },
  {
    value: 2,
    gender: "female",
    name: "Pranoti Kulkarni",
    project: "Deep ALK",
    professor: "Prof. Dr. Kang",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FPranoti.png?alt=media&token=374d2b3c-d0e2-4006-8f24-aef8f2a86945",
  },
  {
    value: 3,
    gender: "male",
    name: "Soham Dhore",
    project:
      "Efficient Scalable Data Parallel Training for Graph Neural Networks",
    professor: "Prof. Dr. Arifuzzaman",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FSoham.png?alt=media&token=0a762d1a-52a0-468c-a58e-dddce1f68f2e",
  },
  {
    value: 4,
    gender: "male",
    name: "Heramb Chaudhari",
    project: "Predicting Covid Trends using different machine learning models",
    professor: "Prof. Dr. Kang",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FHeramb.png?alt=media&token=3f89fbcb-26b6-4e15-92b4-ade496b3a987",
  },
  {
    value: 5,
    gender: "male",
    name: "Shubhan Punde",
    project: "Predicting Covid Trends using different machine learning models",
    professor: "Prof. Dr. Kang",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FShubhan.png?alt=media&token=09e012ba-d608-4f10-be3d-089dbffd7ec3",
  },
  {
    value: 6,
    gender: "female",
    name: "Rajnandini Jadhav",
    project:
      "Efficient parallel implementation of Minimum spanning tree algorithm",
    professor: "Prof. Dr. Arifuzzaman",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FRajnandini.png?alt=media&token=0f2821d3-eb9b-4690-a6d5-20eaca3d6817",
  },
  {
    value: 7,
    gender: "male",
    name: "Ishaan Pathare",
    project: "Predicting Covid Trends using different machine learning models",
    professor: "Prof. Dr. Kang",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FIshaan.png?alt=media&token=0de1a223-3b6e-4588-a852-168d0c49bdc1",
  },
  {
    value: 8,
    gender: "female",
    name: "Swarangi Mundhe",
    project: "Robotics - F1 tenth autonomous racing car",
    professor: "Prof. Dr. Oh",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FSwarangi.png?alt=media&token=7aa6b832-7611-45ac-ab29-66b5d29d65f9",
  },
  {
    value: 9,
    gender: "male",
    name: "Atharva Jadhav",
    project: "Predicting Covid Trends using different machine learning models",
    professor: "Prof. Dr. Kang",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FAtharva.png?alt=media&token=89f7e407-9e02-4e01-8589-e0db83720066",
  },
  {
    value: 10,
    gender: "female",
    name: "Devyani Zarekar",
    project: "Decompiling Deep Neural Network Executables",
    professor: "Prof. Dr. Son",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FDevyani.png?alt=media&token=4c911f51-5d62-4001-808b-1cdd40a776cf",
  },
  {
    value: 11,
    gender: "male",
    name: "Sumant Pakhare",
    project: "Gas Delivery Drone Project",
    professor: "Prof. Dr. Oh",
    img: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Cohort%202024%20Profile%20Photos%2FSumant.png?alt=media&token=d4db0888-cf2d-4b56-b3fd-14c9bee32ff8",
  },
];
