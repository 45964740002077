import React from "react";
import { objectives } from "../utils/Cosmoshub";
import Detailedcards from "../component/DetailedCards";

const Coreofferings = (props) => {
  const changePageFunction = (number) => {
    props.setPage(number);
  };
  return (
    <div className="coreofferings" style={{ backgroundColor: "transparent" }}>
      {/* <div className="coreofferings__offerings">
        <div className="coreofferings__offerings__text">
          ArcticTurn Foundation is based on the principle of responding to the
          needs of the communities it serves, both locally and globally. ATF
          works with diverse partners and stakeholders, both academic and
          industrial, to design and implement solutions that address the
          challenges and opportunities faced by different communities, from
          urban and rural areas in India to other countries around the world.
          ATF's model is flexible, adaptive and scalable, allowing it to deliver
          impactful outcomes across various contexts and domains.
        </div>
      </div> */}
      {/* <div className="coreofferings__section">
        <div className="coreofferings__title">What We Do</div>
        <div className="coreofferings__whatwedo">
          <div className="coreofferings__whatwedo__para">
            <div className="coreofferings__whatwedo__title">
              Facilitating Collaborations
            </div>
            <div className="coreofferings__whatwedo__subtext">
              ATF COSMOS plays a crucial role in fostering collaborations
              between academics, enterprises, and researchers in the field of
              sustainability.
              
            </div>

            
          </div>
          <div className="coreofferings__whatwedo__para">
            <div className="coreofferings__whatwedo__title">
              Knowledge Exchange
            </div>
            <div className="coreofferings__whatwedo__subtext">
              
              providing a platform for academics, enterprises, and researchers
              to share their insights, best practices, and research findings.
            </div>

            
          </div>
          <div className="coreofferings__whatwedo__para">
            <div className="coreofferings__whatwedo__title">
              Create Green Pathways
            </div>
            <div className="coreofferings__whatwedo__subtext">
              As a central hub, ATF COSMOS actively works towards creating green
              pathways that connect individuals and institutions committed to
              sustainability.
              
            </div>

            
          </div>
        </div>
      </div> */}
      <div className="coreofferings__section">
        <div className="coreofferings__title" style={{ color: "black" }}>
          ArcticTurn Foundation Objectives
        </div>
        {/* <div className="coreofferings__whatwedo">
          {objectives.map((obj) => (
            <div className="coreofferings__whatwedo__para">
              <div className="coreofferings__whatwedo__title"></div>
              <div className="coreofferings__whatwedo__subtext">
                {obj.title}
              </div>
            </div>
          ))}
        </div> */}
        <div className="objectives__goals">
          {objectives.map((obj) => (
            <Detailedcards data={obj} details={false} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Coreofferings;
