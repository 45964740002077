export const Flight2024list = [
  {
    value: "1",
    id: "Fellowship",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Group%20photo.jpeg?alt=media&token=e9ea97a5-bbda-44dc-a438-c53ccd32c825",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/unlvcampus.jpg?alt=media&token=aade633c-ad22-410a-9ecf-1b71b615b806",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/unlv%20school.jpg?alt=media&token=6a6a698f-bbf8-4411-af4a-47d7d65ed7ef",
    ],
    Headline: "Fellowship: Cultivating Academic Excellence at UNLV",
    content:
      "Fellowship at the University of Nevada, Las Vegas (UNLV) offers students a unique opportunity to engage in cutting-edge research and academic pursuits. Participants work closely with UNLV faculty, contributing to ongoing projects and developing their own research skills. This immersive experience fosters a spirit of scholarly collaboration and lays the foundation for future academic endeavors.",
  },
  {
    value: "2",
    id: "Learning",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/pranoti_learning.jpeg?alt=media&token=6bc250c3-2063-4229-9dc5-7cfd5a5cfccf",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/group%20discussion.jpg?alt=media&token=df40d4ef-d9d1-42de-994b-2a10d5ad30ea",
    ],
    Headline: "Learning: Acquiring Global Knowledge and Skills",
    content:
      "In FLIGHT, learning goes beyond traditional academics. There are no timetables, syllabii, or rigid schedules. Instead, participants are encouraged to be self-driven, managing their own time and focusing on persistence and depth in their work. This unstructured approach contrasts with the more rigid study patterns they are familiar with in India, teaching interns how to manage their tasks independently, stay focused, and dig deeper into projects with curiosity and determination.",
  },
  {
    value: "3",
    id: "Ideation",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/ideation_image%20(1)%20(1)%20(1).jpg?alt=media&token=f6396d3f-2410-4127-8414-136909205926",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/IMG_20240720_170835.jpg?alt=media&token=dd94d040-1116-47fd-95e5-564a5a6e3eee",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/IMG_20240803_152454%20(1).jpg?alt=media&token=d43b41df-87e0-40fe-b178-1ccfcbd498cf",
    ],
    Headline: "Ideation: Fostering Innovation and Critical Thinking",
    content:
      "FLIGHT encourages innovation and creative problem-solving by tapping into the fresh perspectives of young minds. Enterprises today value the insights of 20-year-olds because they view the world through a different lens, often uncovering solutions that experienced professionals may overlook. In FLIGHT, interns are not only challenged to ideate, prototype, and test solutions for academic tasks and real-world case studies, like those in ISA (now SEED)",
  },
  {
    value: "4",
    id: "Global Citizen",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/global%20citizen.jpg?alt=media&token=9291dc7d-7091-4374-9b1a-028276db78cc",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/global%20ctzn.jpg?alt=media&token=c32011b9-a27b-4e3a-ab2b-53f9bd619f4c",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/globalc.jpg?alt=media&token=a3a5e189-5c3c-4c32-bad7-c232d2a06e10",
    ],
    Headline: "Global Exposure: Broadening Perspectives in a Dynamic Setting",
    content:
      "FLIGHT offers students the opportunity to travel across the globe with a sense of purpose, helping them understand the interconnectedness of our world. Interns learn to embrace diversity—in people, work, places, food, weather, and culture—gaining insights that push them to think beyond local perspectives. By acting without limitations, participants develop the confidence to take bold steps in unfamiliar environments. Additionally, they cultivate a strong sense of social accountability, understanding their role in a global context and the impact of their actions.",
  },
  {
    value: "5",
    id: "Hustle",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/grocery.jpg?alt=media&token=2317796c-ce56-4219-af13-cb3704f15d7f",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/hustle%20heat.jpg?alt=media&token=73a6a185-2c19-4643-8987-0f36752d9b2c",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/airportbags%20(2).jpg?alt=media&token=ff2c32df-bfca-47e6-a5e1-a4551bfc9713",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/food.jpg?alt=media&token=e457e70c-2f49-4d42-8c3b-6b84fbc1f8cb",
    ],
    Headline: "Hustle: Embracing Challenges with Determination",
    content:
      "In FLIGHT, hustle goes beyond academic challenges. Interns are expected to adapt to day-to-day life in the host nation, becoming self-reliant as they manage weekly groceries, keep their living spaces clean, do their own laundry, and take turns using appliances like dishwashers. This emphasis on self-sufficiency teaches interns to navigate the practicalities of independent living, fostering a sense of responsibility and adaptability in a new environment.",
  },
  {
    value: "6",
    id: "Travel",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/la.jpg?alt=media&token=0c93552f-b581-4327-8a4d-0cbd5dbe8c43",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/travel%20lv.jpg?alt=media&token=0a3ae636-6779-4549-b122-492d6932223d",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/dinnergirls.jpg?alt=media&token=23df3e36-3e4a-4250-a51e-558b4fc07c9c",
      "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/underground.jpg?alt=media&token=009f29bd-4a2b-41e2-a3c5-c16f67066808",
    ],
    Headline: "Travel: Expanding Horizons Beyond the Classroom",
    content:
      "The FLIGHT program immerses interns in the complexities of international travel, starting with the rigorous J1 Visa process and local travel within India. Interns must plan for an 8-week stay abroad, carefully managing their baggage and navigating airports, security protocols, and layovers across different countries. This journey emphasizes the importance of time management and planning. Experiencing how a 24-hour flight can transport them from one point of the globe to its antipode instills a sense of being a true global citizen, opening their eyes to the vast interconnectedness of the world.",
  },
];
