import React from "react";

const Multiplepoints = (props) => {
  // console.log(props.data);
  const borderColor = props.border ? "white" : null;
  const align = props.align;
  return (
    <div
      className="aboutpage__whatwedosection"
      //style={{ textAlign: "center" }}
      style={props.border && { borderColor: "transparent" }}
    >
      {/* <Title text="What we do?" /> */}
      {/* <Listwithoutheaders text={whatwedo[0]} /> */}
      {props.text1 === "" && props.text2 === "" && props.text3 === "" ? null : (
        <div className="aboutpage__whatwedosection_title">
          {props.text1} <br />
          <div className="aboutpage__whatwedosection_title_subtitle">
            {props.text2}{" "}
          </div>
          {props.text3}
        </div>
      )}

      <div
        className="aboutpage__whatwedosection__whatwedo"
        //style={{ display: "flex", justifyContent: "center" }}
      >
        {props.data &&
          props.data.map((obj) => (
            <div
              className="aboutpage__whatwedosection__whatwedo__para"
              key={obj.value}
              style={{
                color: props.color,
                textAlign: props.textalign,
              }}
            >
              <div
                key={obj.value}
                style={
                  props.cards && {
                    backgroundColor: "white",
                    height: "100%",
                    borderRadius: "2rem",
                    padding: "2rem",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }
                }
              >
                <div className="aboutpage__whatwedosection__whatwedo__image">
                  {obj.img && (
                    <img
                      className="aboutpage__whatwedosection__whatwedo__image_img"
                      src={obj.img}
                    ></img>
                  )}
                  {obj.valueflag && (
                    <div className="aboutpage__whatwedosection__whatwedo__image_value">
                      {obj.value}
                    </div>
                  )}
                </div>
                <div
                  className="aboutpage__whatwedosection__whatwedo__title"
                  style={props.headcolor !== "" && { color: props.headcolor }}
                >
                  {obj.title}
                </div>{" "}
                <div
                  className="aboutpage__whatwedosection__whatwedo__title2"
                  style={props.color !== "" && { color: props.color }}
                >
                  {obj.text}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Multiplepoints;
