import React from "react";
import { Cohort2024UnlvList } from "../utils/Cohort2024UnlvList";

const Flightcohort = (props) => {
  return (
    <div className="flightcohort">
      <div className="flightcohort__text">
        <div className="flightcohort__text__headline">
          Interns of FLIGHT Mobility 2024
          {/* <div className="flightcohort__text__headline__underline"></div> */}
        </div>
        {/* <div className="flightcohort__text__subheadline">subhealdine</div> */}
      </div>
      <div className="flightcohort__intern">
        {Cohort2024UnlvList.map((intern) => (
          <div key={intern.value} className="flightcohort__intern__card">
            <div className="flightcohort__intern__card__imgsection">
              <img
                src={intern.img}
                className="flightcohort__intern__card__imgsection__img"
              />
            </div>
            <div className="flightcohort__intern__card__infosection">
              <div className="flightcohort__intern__card__infosection__name">
                {intern.gender === "male"
                  ? "Mr." + intern.name
                  : "Ms." + intern.name}
                <div className="flightcohort__intern__card__infosection__name__underline"></div>
              </div>
              <div className="flightcohort__intern__card__infosection__project">
                {intern.project}
              </div>
              <div className="flightcohort__intern__card__infosection__professor">
                {intern.professor}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Flightcohort;
