import React, { useEffect, useState } from "react";
import Collab from "../../Images/sustainability_bg.mp4";
import Windmill_img from "../../Images/windmill_img2.jpg";
import Goal4 from "../../Images/goal4.png";
import Goal12 from "../../Images/goal12.png";
import Goal17 from "../../Images/goal17.png";
import Goal9 from "../../Images/goal9.png";
import Education from "../../Images/education.png";
import Wastewater from "../../Images/wastewater.png";
import Publichealth from "../../Images/publichealth.png";
import sustainableinfra from "../../Images/sustainableinfra.png";
import Noticeboard from "./Noticeboard";
import Usaflag from "../../Images/usaflag.png";
import SDG_hero_video from "../../Images/SDG_hero_video.mp4";
import Collaboration from "./Collaboration";
import ImageCarousel from "../component/ImageCarousel";
import LiveIcon from "../component/LiveIcon";
import Logo from "../../Images/Logo.png";
import UNLVlogo from "../../Images/unlv_logo.jpeg";

import Group_photos from "../../Images/unlv group photo copy.jpeg";
import UNLVcollege from "../../Images/UNLV college .jpeg";
import UNLVhall from "../../Images/UNLV Hall.jpeg";
import UNLVBoys from "../../Images/UNLV Boys .jpeg";
import UNLVbench from "../../Images/UNLV Bench.jpeg";

const Hero = (props) => {
  const [currentWeek, setCurrentWeek] = useState(null);
  const [showFirstDiv, setShowFirstDiv] = useState(false);
  const imageLinks = [Group_photos, UNLVcollege, UNLVhall, UNLVBoys, UNLVbench];

  useEffect(() => {
    // Toggle the visibility of divs after a delay
    const interval = setInterval(() => {
      setShowFirstDiv((prev) => !prev);
    }, 5000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    function updateWeek() {
      setCurrentWeek(getCurrentWeek());
    }

    updateWeek(); // Update immediately

    // Update every day
    const intervalId = setInterval(updateWeek, 24 * 60 * 60 * 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const changePageFunction = (number) => {
    props.setPage(number);
  };

  function getCurrentWeek() {
    const programStart = new Date("2024-07-21");
    const programEnd = new Date("2024-09-14");
    const today = new Date();

    if (today < programStart) return "Program hasn't started yet";
    if (today > programEnd) return "Program has ended";

    const diffTime = Math.abs(today - programStart);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return Math.ceil(diffDays / 7);
  }
  return (
    <>
      {/* <div className="hero">
        <div className="bgvideo">
          <video
            className="bgvideo__content"
            playsInline
            autobuffer="true"
            autoPlay
            muted
            loop
          >
            <source src={Collab} type="video/mp4" />
          </video>

          <img src={Windmill_img} className="bgvideo__content" />

          <div className="slidecontainer">
            <div
              className="box"
              style={{
                transform: showFirstDiv ? "translateX(0)" : "translateX(100%)",
              }}
            >
              {showFirstDiv ? (
                <div className="">
                  <div className="hero__ATFbanner">
                    <div className="hero__ATFbanner__text"></div>
                    <div className="hero__ATFbanner__text">C O S M O S</div>
                    <div className="hero__ATFbanner__text">
                      Empowering
                      <span class="realistic-marker-highlight">
                        Sustainable Solutions
                      </span>
                      for Communities Worldwide
                    </div>

                    <div class="heading-container">
                      <div className="hero__ATFbanner__text">
                        Empowering{" "}
                        <span class="highlight-container">
                          <span class="highlight">Sustainable Solutions</span>
                        </span>{" "}
                        for Communities Worldwide A global corridor facilitator
                        for Academia, Industry and Research.
                      </div>
                    </div>
                    <div className="hero__ATFbanner__text2">
                      An initiative that works in the domains of Education,
                      Public Health, Sustainable Infrastructure and Waste Water
                      Management to address key challenges in these areas.
                    </div>

                    <div className="hero__ATFbanner__SDGicon">
                      <img
                        src={Education}
                        className="hero__ATFbanner__SDGicon__SDGicons"
                      />
                      <img
                        src={Publichealth}
                        className="hero__ATFbanner__SDGicon__SDGicons"
                      />
                      <img
                        src={sustainableinfra}
                        className="hero__ATFbanner__SDGicon__SDGicons"
                      />

                      <img
                        src={Wastewater}
                        className="hero__ATFbanner__SDGicon__SDGicons"
                      />
                    </div>

                    <div className="hero__ATFbanner__text3">
                      Global corridor creating green pathways amongst academics,
                      enterprise & researchers
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div
              className="box"
              style={{
                transform: showFirstDiv ? "translateX(100%)" : "translateX(0)",
              }}
            >
              {showFirstDiv ? null : (
                <div className="hero__ATFbanner">
                  <div className="whattm">
                    <img src={Windmill_img} className="bgvideo__content" />
                    <div className="whattm__header">Arcticturn Foundation</div>
                    <div className="whattm__subheader">F.L.I.G.H.T</div>
                    <div className="whattm__subheader1">
                      Talent Mobility Program 2024
                    </div>
                    <div className="whattm__subheader2">
                      UNIVERSITY OF NEVADA LAS VEGAS , USA{" "}
                      <img
                        src={Usaflag}
                        style={{ width: "3rem", marginLeft: "1rem" }}
                      />
                    </div>

                    <div className="whattm__year">
                      <span className="highlight-container">
                        <span>2024</span>
                      </span>
                    </div>
                    <a href="/mobility" target="_blank">
                      <div className="whattm__applybutton">Know More</div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* <div class="heading-container">
        <div className="hero__ATFbanner__text1">
          
          The ArcticTurn Foundation (ATF) is a global initiative that works in
          the domains of Education, Public Health, Sustainable Infrastructure
          and Waste Water Management to address key challenges in these areas.{" "}

          ATF collaborates with Academia, Industry and Researchers to leverage
          CSR funds and government Grants for social good.
        </div>
      </div> */}

      {/* <div className="imagecarouselsection__headline">
        <div className="collaboration__contentsection__head">
          <div className="collaboration__contentsection__head_title">
            ArcticTurn Foundation
          </div>
          <div className="collaboration__contentsection__head_header">
            Talent Mobility Program
          </div>{" "}
           <div className="collaboration__contentsection__head_title">
            Bridging Borders with Global Learning
          </div> 
        </div>
        <div className="imagecarouselsection__headline__button">
          <a href="/mobility" target="_blank" style={{ borderRadius: "10px" }}>
            <button class="imagecarouselsection__headline__button__style">
              {" "}
              View More
            </button>
          </a>{" "}
        </div>
      </div> */}
      {/* <div className="imagecarouselsection">
        <div className="imagecarouselsection__section">
          <div className="imagecarouselsection__imgsection">
            <img src={Logo} className="imagecarouselsection__logo" />
            <img src={UNLVlogo} className="imagecarouselsection__logo" />
          </div>
          <div className="imagecarouselsection__subheader">
            Talent Mobility Program 2024 - UNLV
          </div>
          <div className="imagecarouselsection__header">
            Students at University of Nevada Las Vegas
          </div>
          <div className="imagecarouselsection__text">
            <LiveIcon /> Course Progress : WEEK{" "}
            {typeof currentWeek === "number" ? currentWeek : currentWeek}
            /8
          </div>
        </div>
        <div>
          <ImageCarousel imageLinks={imageLinks} />
        </div>
      </div> */}

      {/* <Collaboration /> */}
      {/* <Noticeboard /> */}
    </>
  );
};

export default Hero;
