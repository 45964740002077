// Import the functions you need from the SDKs you need
//import firebase from 'firebase/app';
//import { getAnalytics } from "firebase/analytics";
//import "firebase/firestore"

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBB2srzTuQG49eab_Z5ffS26ZZNULVcBBg",
//   authDomain: "thearcticturn.firebaseapp.com",
//   projectId: "thearcticturn",
//   storageBucket: "thearcticturn.appspot.com",
//   messagingSenderId: "465818995599",
//   appId: "1:465818995599:web:290565e965778bc7aa19fa",
//   measurementId: "G-S4R1Z66NMV"
// };

//recent old date-3sept 2024
// const firebaseConfig = {
//   apiKey: "AIzaSyCZ-4N61kYCQjkKlXA_dBGc7AN-NsquCuQ",
//   authDomain: "arcticturn-2-0.firebaseapp.com",
//   projectId: "arcticturn-2-0",
//   storageBucket: "arcticturn-2-0.appspot.com",
//   messagingSenderId: "90491308936",
//   appId: "1:90491308936:web:86a76eed4b9f71adbf883d",
//   measurementId: "G-P3Z00B4QWW"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAVFnzsA6hd5kBMnheFfrOci36-soWY80E",
  authDomain: "arcticturn1.firebaseapp.com",
  projectId: "arcticturn1",
  storageBucket: "arcticturn1.appspot.com",
  messagingSenderId: "391435622359",
  appId: "1:391435622359:web:9538667053c117cd3f9d92",
  measurementId: "G-ZJPN7FQDH4",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth();
export const db = firebase.firestore();

//const analytics = getAnalytics(app);
//const firestore = app.firestore();

export default firebase;
//export {app, firestore};
