import React, { useState, useRef, useEffect } from "react";
import { IoIosMenu } from "react-icons/io";
import Logo from "../../Images/Logo.png";
import SmallLogo from "../../Images/smallLogo.png";
import { HiMenu, HiMenuAlt3 } from "react-icons/hi";
import Drawer from "../component/Drawer";
import { Link } from "react-router-dom";
import Boardingpass2025 from "./Boaringpass2025";

const Navbar = (props) => {
  const [menubuttonClick, setMenubuttonclick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [linkClick, setLinkclick] = useState(null);
  const buttonRef = useRef(null);

  const clickLink = () => {
    setMenubuttonclick(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  function getCardStyle(buttonElement) {
    if (!buttonElement) return {};

    const rect = buttonElement.getBoundingClientRect();
    const initialTop = rect.top + rect.height / 2;
    const initialLeft = rect.left + rect.width / 2;

    return {
      "--initial-top": `${initialTop}px`,
      "--initial-left": `${initialLeft}px`,
    };
  }

  return (
    <div className="topsection">
      <div className="navbar">
        <div className="navbar__logo">
          <img src={Logo} className="navbar__logo" alt="Logo" />
        </div>
        <div className="navbar__smallLogo">
          <img src={Logo} className="navbar__logo__img" alt="Small Logo" />
        </div>
        <div className="menubar">
          {props.menulist.map((element) => (
            <div className="menubar__SecondaryButton" key={element.value}>
              <Link
                className="menubar__SecondaryButton__link"
                to={element.link}
                onClick={() => {
                  setLinkclick(element.value);
                  scrollToSection(
                    `section-${element.title
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`
                  );
                }}
                style={
                  element.value === linkClick || element.value === 7
                    ? {
                        fontWeight: 700,
                        fontSize: "2rem",
                        color: "rgb(1, 51, 51)",
                      }
                    : null
                }
              >
                {element.title}
              </Link>
            </div>
          ))}
        </div>
        <div
          className="navbar__SecondaryButton"
          //onClick={() => scrollToSection("form-and-pass-container-outer")}
          onClick={toggleCard}
        >
          Enroll for F.L.I.G.H.T. 2025
        </div>
        {isOpen && (
          <div className="overlay">
            <div
              className="floatingcard"
              style={getCardStyle(buttonRef.current)}
            >
              <button className="close-button" onClick={toggleCard}>
                X
              </button>
              <div className="floatingcard-content">
                <Boardingpass2025 />
              </div>
            </div>
          </div>
        )}
        <div
          className="navbar__MenuIcon"
          onClick={() => {
            setMenubuttonclick(!menubuttonClick);
          }}
        >
          {menubuttonClick ? <HiMenuAlt3 /> : <HiMenu />}
        </div>
      </div>
      {menubuttonClick ? <Drawer isClicked={() => clickLink()} /> : ""}
    </div>
  );
};

export default Navbar;
