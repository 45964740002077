import React, { useState, useRef, useEffect } from "react";
import { Flight2024list } from "../utils/Flight2024List";
const LazyImage = ({ src, alt, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div ref={imgRef} className={`lazy-image-container ${className}`}>
      {isInView ? (
        <img
          src={src}
          alt={alt}
          className={`lazy-image ${isLoaded ? "loaded" : ""}`}
          onLoad={() => setIsLoaded(true)}
        />
      ) : (
        <div className="image-placeholder">Loading...</div>
      )}
    </div>
  );
};

const ImagesliderContainer = (props) => {
  const [activeItem, setActiveItem] = useState(Flight2024list[0]?.id || "");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const activeContent = Flight2024list.find((item) => item.id === activeItem);
  const handleMenuClick = (item) => {
    setActiveItem(item);
    setCurrentImageIndex(0);
  };
  const nextImage = () => {
    if (activeContent?.images && activeContent.images.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === activeContent.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevImage = () => {
    if (activeContent?.images && activeContent.images.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? activeContent.images.length - 1 : prevIndex - 1
      );
    }
  };
  if (!activeContent) {
    return <div>No content available</div>;
  }
  return (
    <div className="flightinsights-container">
      <nav className="flightinsights-navigation">
        <ul className="flightinsights-menu-list">
          {Flight2024list.map((item) => (
            <li
              key={item.value}
              className={`flightinsights-menu-item ${
                item.id === activeItem ? "flightinsights-menu-item--active" : ""
              }`}
              onClick={() => handleMenuClick(item.id)}
            >
              {item.id}
            </li>
          ))}
        </ul>
      </nav>

      <div className="flightinsights-content">
        <div className="flightinsights-content__left">
          <div className="flightinsights-image-slider">
            {activeContent.images && activeContent.images.length > 0 ? (
              <>
                <LazyImage
                  src={activeContent.images[currentImageIndex]}
                  alt={`${activeContent.id} - Image ${currentImageIndex + 1}`}
                  className="flightinsights-image-slider__image"
                />
                <button
                  className="flightinsights-image-slider__button flightinsights-image-slider__button--prev"
                  onClick={prevImage}
                >
                  &lt;
                </button>
                <button
                  className="flightinsights-image-slider__button flightinsights-image-slider__button--next"
                  onClick={nextImage}
                >
                  &gt;
                </button>
              </>
            ) : (
              <div className="flightinsights-image-slider__placeholder">
                No images available
              </div>
            )}
          </div>
        </div>

        <div className="flightinsights-content__right">
          <h2 className="flightinsights-title">{activeContent.Headline}</h2>
          <p className="flightinsights-description">{activeContent.content}</p>
        </div>
      </div>
    </div>
  );
};

const additionalStyle = `
  .flightinsights-content {
    display: flex;
    gap: 20px;
  }

  .flightinsights-content__left {
    flex: 1;
    max-width: 500px; /* Adjust this value based on your layout needs */
  }

  .flightinsights-image-slider {
    position: relative;
    width: 100%;
    height: 300px; /* Adjust this value to your desired height */
    overflow: hidden;
  }

  .lazy-image-container {
    width: 100%;
    height: 100%;
  }

  .lazy-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    color: #333;
  }

  .flightinsights-image-slider__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }

  .flightinsights-image-slider__button--prev {
    left: 10px;
  }

  .flightinsights-image-slider__button--next {
    right: 10px;
  }

  .flightinsights-content__right {
    flex: 1;
  }
`;

// Make sure to inject this style into your document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = additionalStyle;
document.head.appendChild(styleSheet);
export default ImagesliderContainer;
