import React, { useState, useEffect, useRef } from "react";
import Flight2025 from "../../Images/FLIGHTMobility2025_name.png";

const Boardingpass2025 = () => {
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    phoneNumber: "",
    email: "",
    college: "",
    city: "",
    course: "",
    yearOfStudy: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const courseOptions = [
    "Computer Science Engineering",
    "Information Technology",
    "Mechanical Engineering",
    "Civil Engineering",
    "Electrical Engineering",
    "Electronics and Communication Engineering",
    "Chemical Engineering",
    "Biotechnology",
    "Engineering",
    "Bachelor of Commerce (B.Com)",
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Science (B.Sc)",
    "Bachelor (Any)",
    "Master of Technology (M.Tech)",
    "Master of Science (M.Sc)",
    "Master of Computer Applications (MCA)",
    "Master of Business Administration (MBA)",
    "Master of Commerce (M.Com)",
    "Master of Engineering (M.E)",
    "Masters (Any)",
  ];

  const yearOfStudyOptions = ["1st Year", "2nd Year", "3rd Year", "4th Year"];

  const [submittedData, setSubmittedData] = useState(null);
  const [errors, setErrors] = useState({});
  const barcodeRef = useRef(null);

  useEffect(() => {
    generateBarcode();
  }, []);

  const generateBarcode = () => {
    const barcode = barcodeRef.current;
    if (!barcode) return;

    const containerWidth = barcode.offsetWidth;
    let currentWidth = 0;

    barcode.innerHTML = "";

    while (currentWidth < containerWidth) {
      const line = document.createElement("div");
      const isBar = Math.random() > 0.5;
      line.className = isBar
        ? "boarding-pass__barcode-line"
        : "boarding-pass__barcode-space";
      const width = Math.floor(Math.random() * 3) + 1;
      line.style.width = `${width}px`;
      barcode.appendChild(line);
      currentWidth += width;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "phoneNumber":
        if (!/^\d{10}$/.test(value)) {
          error = "Phone number must be 10 digits";
        }
        break;
      case "email":
        if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Invalid email address";
        }
        break;
      // Add more cases for other fields if needed
    }
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (errors[key] || formData[key] === "") {
        formIsValid = false;
        newErrors[key] = errors[key] || "This field is required";
      }
    });

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      setSubmittedData(formData);

      try {
        console.log("Sending form data:", formData);

        // Create a new FormData object
        const formDataToSend = new FormData();

        // Append each form field to the FormData object
        Object.keys(formData).forEach((key) => {
          formDataToSend.append(key, formData[key]);
        });

        const response = await fetch(
          "https://us-central1-arcticturn1.cloudfunctions.net/submitForm?type=signup",
          {
            method: "POST",
            body: formDataToSend, // Send the FormData object
          }
        );

        console.log("Response status:", response.status);
        const responseText = await response.text();
        console.log("Response text:", responseText);

        if (response.ok) {
          const result = JSON.parse(responseText);
          alert(`Form submitted successfully!`);
          // Clear form fields after successful submission
          setFormData({
            name: "",
            gender: "",
            phoneNumber: "",
            email: "",
            college: "",
            city: "",
            course: "",
            yearOfStudy: "",
          });
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error:", error);
        alert(
          "There was an error submitting the form. Please try again later."
        );
      } finally {
        setIsSubmitting(false);
      }
    } else {
      alert("Please correct the errors before submitting.");
    }
  };

  return (
    <div
      className="form-and-pass-container-outer"
      id="form-and-pass-container-outer"
    >
      <h1 className="registration-title">
        Join the Waitlist <br /> F.L.I.G.H.T. Mobility Program 2025
      </h1>
      <section id="section-register-flight-2025">
        <div className="form-and-pass-container">
          <div className="split-card">
            <div className="split-card__cut split-card__cut--left"></div>
            <div className="split-card__cut split-card__cut--right"></div>
            <div className="split-card__cut split-card__cut--left-bottom"></div>
            <div className="split-card__cut split-card__cut--right-bottom"></div>

            <div className="split-card__left">
              <img
                src={Flight2025}
                alt="FLIGHT Mobility 2025 Event"
                className="event-image"
              />
            </div>

            <div className="split-card__divider"></div>

            <div className="split-card__right">
              <h2>Sign up for FLIGHT 2025 </h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                {errors.name && <span className="error">{errors.name}</span>}
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                {errors.gender && (
                  <span className="error">{errors.gender}</span>
                )}
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  required
                />
                {errors.phoneNumber && (
                  <span className="error">{errors.phoneNumber}</span>
                )}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && <span className="error">{errors.email}</span>}
                <input
                  type="text"
                  name="college"
                  placeholder="College"
                  value={formData.college}
                  onChange={handleInputChange}
                  required
                />
                {errors.college && (
                  <span className="error">{errors.college}</span>
                )}
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
                {errors.city && <span className="error">{errors.city}</span>}
                <select
                  name="course"
                  value={formData.course}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Course</option>
                  {courseOptions.map((course, index) => (
                    <option key={index} value={course}>
                      {course}
                    </option>
                  ))}
                </select>
                {errors.course && (
                  <span className="error">{errors.course}</span>
                )}
                <select
                  name="yearOfStudy"
                  value={formData.yearOfStudy}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Year of Study</option>
                  {yearOfStudyOptions.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errors.yearOfStudy && (
                  <span className="error">{errors.yearOfStudy}</span>
                )}
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
          <div className="boarding-pass">
            <div className="boarding-pass__cut boarding-pass__cut--left"></div>
            <div className="boarding-pass__cut boarding-pass__cut--right"></div>
            <div className="boarding-pass__cut boarding-pass__cut--left-bottom"></div>
            <div className="boarding-pass__cut boarding-pass__cut--right-bottom"></div>

            <img
              src="https://upload.wikimedia.org/wikipedia/commons/d/d1/Delta_logo.svg"
              alt="Delta Logo"
              className="boarding-pass__logo"
            />

            <div className="boarding-pass__cities">
              <div className="boarding-pass__city">
                <div className="boarding-pass__city-code">BOM</div>
                <div className="boarding-pass__city-name">MUMBAI</div>
              </div>
              <div className="boarding-pass__city">
                <div className="boarding-pass__city-code">LAS</div>
                <div className="boarding-pass__city-name">LAS VEGAS</div>
              </div>
            </div>

            <div className="boarding-pass__flight-info">
              <div>
                <p className="boarding-pass__date">June 11, 2025</p>
                <p className="boarding-pass__time">06:05 AM</p>
              </div>
              <div>
                <p className="boarding-pass__flight-time-label">FLIGHT TIME</p>
                <p className="boarding-pass__flight-time">16H 08M</p>
              </div>
              <div>
                <p className="boarding-pass__date">June 12, 2025</p>
                <p className="boarding-pass__time">02:43 PM</p>
              </div>
            </div>

            <div className="boarding-pass__passenger-info">
              <p className="boarding-pass__passenger-label">PASSENGER</p>
              <p className="boarding-pass__passenger-name">
                {formData.gender && formData.name
                  ? `${
                      formData.gender === "male"
                        ? "Mr."
                        : formData.gender === "female"
                        ? "Ms."
                        : ""
                    } ${formData.name}`
                  : formData.name || "Enter Name"}
              </p>
            </div>

            <div className="boarding-pass__info-grid">
              <div className="boarding-pass__info-item">
                <p className="boarding-pass__info-label">CLASS</p>
                <p className="boarding-pass__info-value">ECONOMY</p>
              </div>
              <div className="boarding-pass__info-item">
                <p className="boarding-pass__info-label">FLIGHT NO.</p>
                <p className="boarding-pass__info-value">469023</p>
              </div>
              <div className="boarding-pass__info-item">
                <p className="boarding-pass__info-label">GATE</p>
                <p className="boarding-pass__info-value">B2</p>
              </div>
              <div className="boarding-pass__info-item">
                <p className="boarding-pass__info-label">SEAT</p>
                <p className="boarding-pass__info-value">G18</p>
              </div>
            </div>

            <div className="boarding-pass__barcode" ref={barcodeRef}></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Boardingpass2025;
