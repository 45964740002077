import React, { useEffect } from "react";
import Header from "./Header";
import SecondSection from "./SecondSection";
import NewThirdsection from "./NewThirdsection";
import FourthSection from "./FourthSection";
import NEP from "./NEP";
import Footer from "./Footer";
import Founders from "../Founders";
import Aboutus from "../aboutus";
import Purpose from "./Purpose";
import Hero from "./Hero";
import Noticeboard from "./Noticeboard";
import Coreofferings from "./Coreofferings";
import Blogsection from "./Blogsection";
import Contactsection from "./Contactsection";
import Collaboration from "./Collaboration";
import Linkedinwall from "./Linkedinwall";
import SDG from "./SDG";
import HeroSection from "./Herosection";
import Flightinsights from "./Flightinsights";
import Boardingpass2025 from "./Boaringpass2025";
import BrochureContact from "./BrochureContact";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeroSection />
      <Flightinsights />
      <Boardingpass2025 />
      <Hero />
      {/* <Noticeboard /> */}
      {/* <Collaboration /> */}
      <Linkedinwall />
      <div style={{ backgroundColor: "#f7efe5" }}>
        <BrochureContact />
      </div>
      {/* <SDG /> */}
      <Coreofferings />
      {/* <Contactsection /> */}

      {/* <Blogsection /> */}

      <Footer />

      {/* <Header />
      <SecondSection />
      <NewThirdsection />
      <NEP />
      <FourthSection />
      <Founders />
      <Purpose />
      <Aboutus /> */}
      {/* <Founders /> */}
      {/* <Purpose /> */}
    </div>
  );
};

export default Homepage;
