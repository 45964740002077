import React, { useState, useRef, useEffect } from "react";
import { Play } from "lucide-react";

const LazyVideoPlayer = ({ src, poster }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isIntersecting && videoRef.current) {
      videoRef.current.load();
    }
  }, [isIntersecting]);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div ref={containerRef} className="video-container">
      {isIntersecting ? (
        <>
          <video
            ref={videoRef}
            // width="100%"
            // height="100%"
            className="story-video"
            playsInline
            preload="none"
            loop
            onClick={togglePlay}
            poster={poster}
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && (
            <div className="video-overlay" onClick={togglePlay}>
              <button className="custom-play-button">
                <Play size={50} color="rgb(50, 203, 203)" />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="video-placeholder">
          <img
            src={poster}
            alt="Video thumbnail"
            className="video-thumbnail"
            style={{ width: "2rem" }}
          />
        </div>
      )}
    </div>
  );
};

const TestimonialSection = (props) => {
  const videos = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/PK.mp4?alt=media&token=2e19513d-98e7-4182-873b-cc47607bf54d",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Pranoti%20Kulkarni_thumb.jpg?alt=media&token=5b8195e9-1bb7-437e-97e9-925027a32c7e",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/SP.mp4?alt=media&token=86577ebd-2779-4636-895d-dff68cdeec50",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Testimonial%20Thumbnails%2FShubhan%20punde.jpg?alt=media&token=ac24dc9b-fda8-48e7-9c8b-a8cf6225d81c",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/SD.mp4?alt=media&token=4e04213e-7e62-4e8f-adc9-d521927f85de",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Testimonial%20Thumbnails%2FSoham%20Dhore.jpg?alt=media&token=f57e1b41-70f7-4600-95d9-1ef06965c7b5",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/RJ.mp4?alt=media&token=6429c279-ac60-42e0-a491-bf20cbb8bb34",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/arcticturn1.appspot.com/o/Testimonial%20Thumbnails%2Frajnandini%20jadhav.jpg?alt=media&token=5aefe339-5e14-4f6d-8b3c-1a38ee333e64",
    },
  ];

  return (
    <div className="internexperience">
      {videos.map((video, index) => (
        <div key={index} className="internexperience_videosection">
          <LazyVideoPlayer src={video.src} poster={video.poster} />
        </div>
      ))}
    </div>
  );
};

const additionalStyle = `
  .flightinsights-content {
    display: flex;
    gap: 20px;
  }

  .flightinsights-content__left {
    flex: 1;
    max-width: 500px; /* Adjust this value based on your layout needs */
  }

  .flightinsights-image-slider {
    position: relative;
    width: 100%;
    height: 300px; /* Adjust this value to your desired height */
    overflow: hidden;
  }

  .lazy-image-container {
    width: 100%;
    height: 100%;
  }

  .lazy-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    color: #333;
  }

  .flightinsights-image-slider__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }

  .flightinsights-image-slider__button--prev {
    left: 10px;
  }

  .flightinsights-image-slider__button--next {
    right: 10px;
  }

  .flightinsights-content__right {
    flex: 1;
  }
`;

// Make sure to inject this style into your document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = additionalStyle;
document.head.appendChild(styleSheet);

export default TestimonialSection;
