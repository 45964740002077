import React, { useEffect, useRef, useState } from "react";
import Windmill_img from "../../Images/windmill_img2.jpg";
import Cover from "../../Images/mobilityCover.jpeg";
import UNLVBuilding from "../../Images/UNLV_Building.jpg";
import UNLVlogo from "../../Images/unlv_logo.jpeg";
import SGDLOGO from "../../Images/SDG Wheel_PRINT_Transparent.png";
import Usaflag from "../../Images/usaflag.png";
import Indiaflag from "../../Images/indiaflag.webp";
import FLIGHTMOBILITY2024 from "../../Images/FLIGHTMOBILITY2024.jpeg";
import Brochureimg from "../../Images/unlv.jpeg";
import SDG4 from "../../Images/SGC4.jpeg";
import Logo from "../../Images/Logo.png";
import SmallLogo from "../../Images/smallLogo.png";
import VITLogo from "../../Images/VIT-logo.png";
import Student_project from "../../Images/student_project.png";
import SDG17 from "../../Images/SGC17.jpeg";
import Arrow from "../../Images/button_arrow.png";
import ISA from "../../Images/ISA.jpeg";
import Multiplepoints from "../component/BlogsComponents/Multiplepoints";
import {
  unlvprojectlist,
  week8features,
  whatwedo,
  whytm,
} from "../utils/AboutInfo";
import Footer from "./Footer";
import ImageCarousel from "../component/ImageCarousel";
import LiveIcon from "../component/LiveIcon";

import Group_photos from "../../Images/unlv group photo copy.jpeg";
import UNLVcollege from "../../Images/UNLV college .jpeg";
import UNLVhall from "../../Images/UNLV Hall.jpeg";
import UNLVBoys from "../../Images/UNLV Boys .jpeg";
import UNLVbench from "../../Images/UNLV Bench.jpeg";
import { Play } from "lucide-react"; // Make sure to install lucide-react
import FLIGHTvideo from "../../Videos/FLIGHTvideo.mp4";
import { Flight2024list } from "../utils/Flight2024List";
import Boardingpass2025 from "./Boaringpass2025";
import BrochureContact from "./BrochureContact";
import TestimonialSection from "../component/TestimonialSection";
import ImagesliderContainer from "../component/ImagesliderContainer";
import Flightcohort from "../component/Flightcohort";

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [src]);

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  ////FLIGHT meaning section

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        className="story-video"
        playsInline
        preload="none"
        loop
        onClick={togglePlay}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <div className="video-overlay" onClick={togglePlay}>
          <button className="custom-play-button">
            <Play size={50} color="rgb(50, 203, 203)" />
          </button>
        </div>
      )}
    </div>
  );
};

const Mobility = (props) => {
  ////FLIGHT meaning section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    function updateWeek() {
      setCurrentWeek(getCurrentWeek());
    }

    updateWeek(); // Update immediately

    // Update every day
    const intervalId = setInterval(updateWeek, 24 * 60 * 60 * 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const [card, setCard] = useState(1);
  const [currentWeek, setCurrentWeek] = useState(null);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const imageLinks = [Group_photos, UNLVcollege, UNLVhall, UNLVBoys, UNLVbench];

  function getCurrentWeek() {
    const programStart = new Date("2024-07-21");
    const programEnd = new Date("2024-09-14");
    const today = new Date();

    if (today < programStart) return "Program hasn't started yet";
    if (today > programEnd) return "Program has ended";

    const diffTime = Math.abs(today - programStart);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return Math.ceil(diffDays / 7);
  }

  return (
    <div className="FLIGHTPage">
      {/* First Section */}
      {/* <div className="hero" style={{ marginTop: "0rem" }}>
        <div className="bgvideo">
          <img src={Cover} className="bgvideo__content" />
          <div className="hero__ATFbanner">
            <div className="whattm">
              <div className="whattm__header">Arcticturn Foundation</div>
              <div className="whattm__subheader">F.L.I.G.H.T</div>
              <div className="whattm__subheader1">
                Talent Mobility Program 2024
              </div>
              <div className="whattm__subheader2">
                UNIVERSITY OF NEVADA LAS VEGAS , USA{" "}
                <img
                  src={Usaflag}
                  style={{ width: "3rem", marginLeft: "1rem" }}
                />
              </div>

              <div className="whattm__year">
                <span className="highlight-container">
                  <span>2024</span>
                </span>
              </div>

            </div>
          </div>
        </div>
      </div> */}

      <div className="flight-mobility-hero">
        <div className="flight-mobility-hero-content">
          <h1 className="flight-mobility-hero-content-title">
            F.L.I.G.H.T Mobility Program
          </h1>
          <p className="flight-mobility-hero-content-title-subtitle">
            Connecting minds, bridging innovations, and shaping the future.
          </p>

          <div
            className="buttons"
            onClick={() => scrollToSection("form-and-pass-container-outer")}
          >
            <button className="primary-button">
              Register for 2025 <span className="arrow">→</span>
            </button>
          </div>
        </div>
        {/* <div className="video-container">
          <div className="video-wrapper">
            <div className="video-placeholder">
              <VideoPlayer src={FLIGHTvideo} />
            </div>
          </div>
        </div> */}
        <div className="img-container">
          <img src={FLIGHTMOBILITY2024} className="image-container-flight" />
        </div>
      </div>

      <ImagesliderContainer />

      {/* <div className="imagecarouselsection">
        <div className="imagecarouselsection__section">
          <div className="imagecarouselsection__imgsection">
            <img src={Logo} className="imagecarouselsection__logo" />
            <img src={UNLVlogo} className="imagecarouselsection__logo" />
          </div>
          <div className="imagecarouselsection__subheader">
            Talent Mobility Program 2024 - UNLV
          </div>
          <div className="imagecarouselsection__header">
            Students at University of Nevada Las Vegas
          </div>
          <div className="imagecarouselsection__text">
            <LiveIcon /> Course Progress : WEEK{" "}
            {typeof currentWeek === "number" ? currentWeek : currentWeek}
            /8
          </div>
        </div>
        <div>
          <ImageCarousel imageLinks={imageLinks} />
        </div>
      </div> */}

      {/* Seventh section*/}
      <div className="projectdetails">
        <div className="projectdetails__title">
          8 Weeks guided fellowship 2024 : F.L.I.G.H.T
        </div>
        <div className="projectdetails__para">
          This talent mobility program gives opportunity to work upon impactful
          projects under the guidance of seasoned professors. which gives
          talents a 360 degree learning experience. Concluding this program with
          the journey through Fellowship, Learning, Ideation, Global exposure,
          Hustle & Travel is designed for comprehensive growth.
        </div>
      </div>

      {/* Flow of the program*/}
      <div className="flowoftheprogram">
        <div className="flowoftheprogram__title">Flow of the program</div>
        <div className="flowoftheprogram__body">
          <div className="flowoftheprogram__body__card">
            <div className="flowoftheprogram__body__card__header">
              Pre Process
            </div>
            <div className="flowoftheprogram__body__card__infocard">
              <div className="flowoftheprogram__body__card__infocard__first">
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Nature :
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    Online
                  </div>
                </div>
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Duration :
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    Mar 2024 - May 2024
                  </div>
                </div>
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Location:
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    India
                    <img
                      src={Indiaflag}
                      style={{ width: "2rem", marginLeft: "1rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="flowoftheprogram__body__card__infocard__second">
                <div className="flowoftheprogram__body__card__infocard__second__title">
                  Objectives
                </div>
                <div className="flowoftheprogram__body__card__infocard__second__objectives">
                  <li>Know your host University</li>
                  <li>Know your professor</li>
                  <li>Subject wise preparation</li>
                  <li>Travel and accommodation advice</li>
                  <li>Indian SME allocation event</li>
                </div>
              </div>
            </div>
          </div>

          <div
            className="flowoftheprogram__body__card"
            style={
              {
                //backgroundColor: "#d4f1f4",
                //color: "white",
              }
            }
          >
            <div className="flowoftheprogram__body__card__header">
              F.L.I.G.H.T. Program
            </div>
            <div className="flowoftheprogram__body__card__infocard">
              <div
                className="flowoftheprogram__body__card__infocard__first"
                style={{ borderBottomColor: "white" }}
              >
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Nature :
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    In Person at UNLV, USA
                  </div>
                </div>
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Duration :
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    June 2024 - August 2024
                  </div>
                </div>
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Location:
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    USA
                    <img
                      src={Usaflag}
                      style={{ width: "2rem", marginLeft: "1rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="flowoftheprogram__body__card__infocard__second">
                <div className="flowoftheprogram__body__card__infocard__second__title">
                  Objectives
                </div>
                <div className="flowoftheprogram__body__card__infocard__second__objectives">
                  <li>Campus tour</li>
                  <li>Orientation</li>
                  <li>Project work</li>
                  <li>Industrial Trek</li>
                  <li>Cultural exploration</li>
                  {/* <li style={{ display: "inline-block", marginLeft: "2.2rem" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        className="week8__top__week8logo"
                        style={{ fontSize: "2rem" }}
                      >
                        <p
                          className="week8__top__week8logo__firsthalf"
                          style={{ color: "white" }}
                        >
                          WEEK
                        </p>
                        <p className="week8__top__week8logo__secondhalf">8</p>
                      </div>{" "}
                      <p style={{ marginLeft: "0.5rem" }}>event</p>
                    </div>
                  </li> */}
                </div>
              </div>
            </div>
          </div>

          <div className="flowoftheprogram__body__card">
            <div className="flowoftheprogram__body__card__header">
              Post Process
            </div>
            <div className="flowoftheprogram__body__card__infocard">
              <div className="flowoftheprogram__body__card__infocard__first">
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Nature :
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    Online
                  </div>
                </div>
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Duration :
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    Sept 2024 - Nov 2024
                  </div>
                </div>
                <div className="flowoftheprogram__body__card__infocard__first__jodi">
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__title">
                    Location:
                  </div>
                  <div className="flowoftheprogram__body__card__infocard__first__jodi__subtitle">
                    India
                    <img
                      src={Indiaflag}
                      style={{ width: "2rem", marginLeft: "1rem" }}
                    />
                  </div>
                </div>
              </div>
              <div className="flowoftheprogram__body__card__infocard__second">
                <div className="flowoftheprogram__body__card__infocard__second__title">
                  Objectives
                </div>
                <div className="flowoftheprogram__body__card__infocard__second__objectives">
                  <li>Project presentation</li>
                  <li>Indian industry oriented meet-up sessions</li>
                  <li>Follow up task allocation</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fourth section :  8 weeks */}
      <div className="howtm">
        <div className="howtm__headline">
          <div className="howtm__headline__text">F.L.I.G.H.T Program</div>
          <div className="howtm__SDG">
            <img className="howtm__SDG__img" src={SGDLOGO}></img>
            <img className="howtm__SDG__img" src={SDG4}></img>
            <img className="howtm__SDG__img" src={SDG17}></img>
          </div>
        </div>

        <div className="howtm__cards">
          <div className="howtm__cards__card">
            <div className="howtm__cards__card__header">
              <p>1st</p>
              <span>WEEK</span>
            </div>
            <div className="howtm__cards__card__list">
              <li>Campus Tour</li>
              <li>Orientation</li>
              <li>Group formation</li>
              <li>Project initiation</li>
            </div>
          </div>
          <div className="howtm__cards__card">
            <div className="howtm__cards__card__header">
              <p>2nd - 7th</p>
              <p>WEEK</p>
            </div>
            <div className="howtm__cards__card__list">
              <li>Intensive project work</li>
              <li>Cultural exploration</li>
              <li>Weekly presentations</li>
            </div>
          </div>
          <div className="howtm__cards__card">
            <div className="howtm__cards__card__header">
              <p>8th</p>
              <p>WEEK</p>
            </div>
            <div className="howtm__cards__card__list">
              {/* <li style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="week8__top__week8logo"
                  style={{ fontSize: "2rem" }}
                >
                  <p
                    className="week8__top__week8logo__firsthalf"
                    style={{ color: "white" }}
                  >
                    WEEK
                  </p>
                  <p className="week8__top__week8logo__secondhalf">8</p>
                </div>{" "}
                <p style={{ marginLeft: "0.5rem" }}>event</p>
              </li> */}
              <li>Closing ceremony</li>
            </div>
          </div>
        </div>
      </div>

      {/* 8 Weeks for mobile  */}
      <div className="howtmmb">
        <div className="howtmmb__headline">
          <div className="howtmmb__headline__text">F.L.I.G.H.T Program</div>
        </div>

        <div className="howtmmb__cards">
          <div className="howtmmb__cards__card">
            <div className="howtmmb__cards__card__header">
              <p>1st</p>
              <span>WEEK</span>
            </div>
            <div className="howtmmb__cards__card__list">
              <li>Campus Tour</li>
              <li>Orientation</li>
              <li>Group formation</li>
              <li>Project initiation</li>
            </div>
          </div>
          <div className="howtmmb__cards__card">
            <div className="howtmmb__cards__card__header">
              <p>2nd - 7th</p>
              <p>WEEK</p>
            </div>
            <div className="howtmmb__cards__card__list">
              <li>Intensive project work</li>
              <li>Cultural exploration</li>
              <li>Weekly presentations</li>
            </div>
          </div>
          <div className="howtmmb__cards__card">
            <div className="howtmmb__cards__card__header">
              <p>8th</p>
              <p>WEEK</p>
            </div>
            <div className="howtmmb__cards__card__list">
              <li style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="week8__top__week8logo"
                  style={{ fontSize: "2rem" }}
                >
                  <p
                    className="week8__top__week8logo__firsthalf"
                    style={{ color: "white" }}
                  >
                    WEEK
                  </p>
                  <p className="week8__top__week8logo__secondhalf">8</p>
                </div>{" "}
                <p style={{ marginLeft: "0.5rem" }}>event</p>
              </li>
              <li>Closing ceremony</li>
            </div>
          </div>
        </div>
      </div>

      {/* Fifth section */}
      {/* <div className="projectlisttm">
        <div className="projectlisttm__header">EXPLORE PROJECT DOMAINS</div>
        <div className="projectlisttm__content">
          <div className="projectlisttm__content__list">
            {unlvprojectlist.map((project) => (
              <div className="projectlisttm__project">{project.title}</div>
            ))}
          </div>
          <div className="projectlisttm__content__image">
            <img
              className="cosmoshub__content__section__imgsection_img"
              style={{ padding: "2rem", borderRadius: "3rem" }}
              src={Student_project}
            />
          </div>
        </div>
      </div> */}

      {/* Sixth section */}
      {/* <div className="week8">
        <div className="week8__top">
          <img src={SmallLogo} className="week8__top__logo"></img>
          <div className="week8__top__week8logo">
            <p className="week8__top__week8logo__firsthalf">WEEK</p>
            <p className="week8__top__week8logo__secondhalf">8</p>
          </div>
          <div className="week8__top__subtitle">
            A COLLABORATIVE & UNPARALLELED INTERACTIVE EVENT.
          </div>
        </div>
        <div className="week8__main">
          <div className="week8__main__cards">
            {week8features.map((activity) => (
              <>
                <div
                  className="week8__main__cards__cardpc"
                  onMouseOver={() => {
                    setCard(activity.value);
                  }}
                  style={
                    card === activity.value ? { marginBottom: "-3rem" } : null
                  }
                >
                  <div className="week8__main__cards__cardpc__icon">
                    {" "}
                    <img
                      className="cosmoshub__content__section__imgsection_img"
                      style={{
                        padding: "2rem",
                        borderRadius: "3rem",
                      }}
                      src={activity.img}
                    />
                  </div>
                  <div className="week8__main__cards__cardpc__title">
                    {activity.title == "Industrial Trek" ? (
                      <>
                        {activity.title} <span>&#42;</span>
                      </>
                    ) : (
                      activity.title
                    )}
                  </div>
                </div>
                <div
                  className="week8__main__cards__cardmobo"
                  onMouseOver={() => {
                    setCard(activity.value);
                  }}
                >
                  <div className="week8__main__cards__cardmobo__icon">
                    {" "}
                    <img
                      className="cosmoshub__content__section__imgsection_img"
                      style={{ padding: "2rem", borderRadius: "3rem" }}
                      src={activity.img}
                    />
                  </div>
                  <div className="week8__main__cards__cardmobo__title">
                    {activity.title}
                  </div>

                  <div className="week8__main__body__des">{activity.text}</div>
                </div>
              </>
            ))}
          </div>
          <div className="week8__main__body">
            <div className="week8__main__body__des">
              {week8features.map(
                (temp) =>
                  temp.value === card && (
                    <div className="week8__main__body__des__text">
                      {" "}
                      {temp.text}{" "}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* ISA Brochure download  */}
      {/* <div className="brochure">
        <img className="brochure__img" src={ISA}></img>
        <div className="brochure__title"> Indian SME Allocation Brochure</div>
        <a
          target="_blank"
          href="https://drive.google.com/file/d/1SHfI3EKNLwVdu2eXwSWCCGYp9CzMLcOA/view?usp=sharing"
        >
          <div className="brochure__button"> VIEW</div>
        </a>
      </div> */}

      {/* Third Section */}
      {/* <div className="SGC__colorliner" style={{ marginTop: "5rem" }}></div> */}
      <div className="whytm" style={{ backgroundColor: "transparent" }}>
        <div className="whytm__header" style={{ color: "rgb(1, 51, 51)" }}>
          Your Experiencial Education Journey Starts with ATF Talent Mobility
          Program
        </div>
        <span className="highlighter"></span>

        <Multiplepoints
          text1=""
          text2=""
          text3=""
          border="false"
          data={whytm}
          headcolor="black"
          color="#1c7c7c"
          textalign="center"
          cards={true}
        />
      </div>
      {/* <div className="SGC__colorliner" style={{ marginTop: "0rem" }}></div> */}

      {/* Students testimonial  */}
      <div className="internexperience-headline">
        FLIGHT Interns Experiencial Journey
      </div>
      <TestimonialSection />

      {/* boarding pass  */}
      <div style={{ marginTop: "5rem" }}>
        <Boardingpass2025 />
      </div>

      <Flightcohort />

      {/* Second Section */}
      <div className="cosmoshub__content">
        <div className="cosmoshub__content__section">
          <div
            className="cosmoshub__content__section__textsection"
            style={{ display: "flex", margin: "2rem 0" }}
          >
            <img src={UNLVlogo} className="wheretm__logo" />
            <div className="wheretm__header">
              University of Nevada Las Vegas, USA
            </div>
            <div className="wheretm__points">
              <div className="wheretm__points_point">
                Carnegie R1 Research University
              </div>
              <div className="wheretm__points_point">
                Assistanceship Opportunity
              </div>
              <div className="wheretm__points_point">
                Funding Availability<span>&#42;</span>
              </div>
              <div className="wheretm__points_point">
                State-of-the-art Infrastructure
              </div>
            </div>
            <a href="https://www.unlv.edu/about" target="_blank">
              <div
                className="wheretm__button"
                style={{ backgroundColor: "#015a9a" }}
              >
                <span>Learn More about UNLV </span>
                <img src={Arrow} className="wheretm__arrowimg" />
              </div>
            </a>
          </div>
          <div className="cosmoshub__content__section__imgsection">
            {/* //Image size is 1080x680 */}
            <img
              className="cosmoshub__content__section__imgsection_img"
              style={{ padding: "2rem", borderRadius: "3rem" }}
              src={UNLVBuilding}
            />
          </div>
        </div>
      </div>

      {/* Brochure download  */}
      {/* <div className="brochure">
        <img className="brochure__img" src={Brochureimg}></img>
        <div className="brochure__title">
          {" "}
          Talent Moblity 2024, UNLV Brochure
        </div>
        <a
          target="_blank"
          href="https://drive.google.com/file/d/1E_cXyWoCdhiR2ADY9K2ZqV2WYjIUtae0/view?usp=sharing"
        >
          <div className="brochure__button"> VIEW</div>
        </a>
      </div> */}
      <BrochureContact />

      <Footer />
    </div>
  );
};

export default Mobility;
